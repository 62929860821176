import React from 'react';
import { Routes, Route } from "react-router-dom";

/* Pages */
import Homepage from '../pages/Homepage';
import Articles from '../pages/news/Articles';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Userpage from '../pages/Userpage';
import Adminpage from '../pages/Adminpage';
import Subscribe from '../pages/Subscribe';
import Error404page from '../pages/Error404page';

import UserType from '../types/user.type';

interface LayoutProps {
    user: UserType | null,
    coord: coord
}
interface coord {
    latitude:number
    longitude:number
}
function Layout() {
    // const user = props.user;
    // const coord = props.coord
    return(
        <main id="main_container" className="container">
            <Routes>
                <Route path='/' element={<Homepage />} />
                <Route path="/news">
                    <Route index element={<Articles />} />
                    <Route path="/news/:topic" element={<Articles />} />
                </Route>
                <Route path='/mypage' element={<Userpage />} />
                <Route path='/admin' element={<Adminpage />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/subscribe' element={<Subscribe />} />
                <Route path='*' element={<Error404page />} />
            </Routes>
        </main>
    );
}


export default Layout;
/*
class Layout extends React.Component {
    render() {
        return(
            <main id="main_container" className="container">
                <Routes>
                    <Route path='/' element={<Homepage />} />
                    <Route path="/news">
                        <Route index element={<Articles />} />
                        <Route path="/news/:topic" element={<Articles />} />
                    </Route>
                    <Route path='/mypage' element={<Userpage />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='*' element={<Error404page />} />
                </Routes>
            </main>
        );
    }
};
*/