import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser, checkToken, logout } from "../services/auth.service";
import UserType from '../types/user.type'
import {checkTokenSuccess, checkTokenError} from '../types/auth'

const Userpage: React.FC = () => {
    let navigate: NavigateFunction = useNavigate();
    const [fetching, setFetching] = useState<boolean>(false)
    const [tokenExpire, setTokenExpire] = useState<string | null>(null)
    const [currentUser, setCurrentUser] = useState<UserType | null>(null)
    useEffect(() => {
        const setUserData = async () => {
            setFetching(true)
            const user = getCurrentUser();
            if (user === null){
                navigate('/login')
            }
            else {
                await checkToken().then(async response => {
                    // console.log({checkTokenResponse:response});
                    if ( response as checkTokenError  && response.error?.name === "TokenExpiredError"){
                        logout();
                    }
                    else if ( response as checkTokenSuccess ){
                        setTokenExpire( response.user.expires )
                        setCurrentUser(user)
                    }
                }).catch(error => {
                    console.log({error});
                })
            }
            setFetching(false)
        }
        setUserData();
    }, []);
    
    return (
        <>
            <div className="userpage_container">
                <div className="p-5 mb-4 bg-light rounded-3 hp-first-row">
                    <div className="container-fluid py-5">
                        { (fetching) ? (<div className="my-2 fetching-div">Fetching user data...</div>):('')}
                        { (currentUser)? 
                        (
                            <>
                                <div className="jumbotron" id={"user_" + currentUser._id}>
                                    <h1><strong>Profile</strong> - {currentUser.firstname} {currentUser.lastname}</h1>
                                </div>
                                <p>
                                    <strong>Email:</strong> {currentUser.email}
                                </p>
                                <p>
                                    <strong>Date Created:</strong> {currentUser.createdAt}
                                </p>
                                {(tokenExpire !== null)? (
                                    <p>
                                        <strong>Token Expiration Time:</strong> {tokenExpire}
                                    </p>
                                ):('')}
                            </>
                        ):
                        (
                            (fetching)?(''):(<div>No user data</div>)
                        )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default Userpage;

/*
<p><strong>firstname:</strong> {currentUser.firstname}</p>
<p><strong>lastname:</strong> {currentUser.lastname}</p>
<p><strong>Id:</strong> {currentUser._id}</p>


<p>
    <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{" "}
    {currentUser.token.slice(currentUser.token.length - 20)}
</p>
<strong>Authorities:</strong>
<ul>
    {currentUser.roles &&
    currentUser.roles.map((role: string, index: number) => <li key={index}>{role}</li>)}
</ul>

*/