import React, { useState, useEffect } from "react";
import axios from 'axios'
import { ReactComponent as Reload } from "./WeatherCard/icons/arrow-repeat.svg";
import {weatherAPIDataType} from './WeatherCard/types/WeatherAPI'
import './WeatherCard/scss/weatherCard.scss'
import api_url from "./WeatherCard/api_url.json";

interface coords {
    latitude: number|null
    longitude: number|null
}
const getWeather = async (latitude:number, longitude:number) => {
    const APISettings = api_url.API_WEATHERAPI
    const weatherAPI_URL = APISettings.URL + APISettings.current + APISettings.KEYSlug + APISettings.KEY + APISettings.QUERY + latitude + ',' + longitude
    await new Promise((r) => setTimeout(r, 500))
    const { data } = await axios.get(
        weatherAPI_URL, {
            headers: {
                'Content-Type'  : 'application/json',
            }
        }
    )
    return data
}

const getLocationWeather = async () => {
    const location_URL = api_url.GEOLOC_API.URL + api_url.GEOLOC_API.APIKEY
    await new Promise((r) => setTimeout(r, 500))
    const data  = await axios.get(
        location_URL, {
            headers: {
                'Content-Type'  : 'application/json',
            }
        }).then(async (response) => {
            return await getWeather(response.data.latitude,response.data.longitude)
        }).catch( error => console.log(error) )
    return data;
}

const weatherCodesToClass = (code:number) => {
    if (code === 1003) return 'partly-cloudy'
    if (code === 1000) return 'sunny'
    else return 'rain'
}


function WeatherCard() {
    const [fetching, setFetching] = useState<boolean>(false)
    const [weather, setWeather] = useState<weatherAPIDataType | null>(null)
    let condidtionCodeClass = 'fetching'
    const setLocationGetWeather = async () => {
        setFetching(true)
        let coord:coords = {latitude:null, longitude:null}
        navigator.geolocation.getCurrentPosition((position) => {
            coord.latitude = position.coords.latitude
            coord.longitude = position.coords.longitude
        });
        await new Promise((r) => setTimeout(r, 200))
        let data;
        if(coord.latitude !== null && coord.longitude !== null){
            data = await getWeather(coord.latitude,coord.longitude)
        }
        else{
            data = await getLocationWeather()
        }
        setWeather(data)
        setFetching(false)
    }
    useEffect(() => {
        setLocationGetWeather();
    }, []);
    if (weather){
        // console.log('weather condition code', weather.current.condition.code);
        condidtionCodeClass = weatherCodesToClass(weather.current.condition.code)
    }
    return (
        <div className={ `weather-card one ${ (fetching) ? "fetching":(condidtionCodeClass)}` }>
            <>
                <div className="top">
                    <div className="wrapper">
                        { (fetching) ? (<div className="fetching-div">Fetching weather...</div>):('')}
                        { (weather) ? (
                            <>
                                <div className="options-nav">
                                    <button className="btn btn-transparent btn-reload" onClick={() => setLocationGetWeather()}>
                                        <Reload className="reload_icon" />
                                    </button>
                                </div>
                                <h1 className="heading">{weather.current.condition.text}</h1>
                                <h3 className="location">{weather.location.name + ', ' + weather.location.country}</h3>
                                <p className="temp">
                                    <span className="temp-value">{weather.current.temp_c}</span>
                                    <span className="deg">0</span>
                                    <span className="temp-type">C</span>
                                </p>
                                <div className="poweredByLink">Powered by <a href="https://www.weatherapi.com/" title="Free Weather API">WeatherAPI.com</a></div>
                            </>
                        ):(
                        <>
                            <div className="options-nav"> &nbsp; </div>
                            <h1 className="heading"> _______ </h1>
                            <h3 className="location">_______</h3>
                            <p className="temp">
                                <span className="temp-value">..</span>
                                <span className="deg">0</span>
                                <span className="temp-type">C</span>
                            </p>
                        </>)}
                        
                    </div>
                </div>

                { (weather) ? (
                    <div className="bottom">
                        <div className="wrapper">
                            <ul className="forecast">
                                {/* <a href="#"><span className="lnr lnr-chevron-up go-up"></span></a> */}
                                <li className="active">
                                    <span className="date">Feels like</span>
                                    <span className="lnr lnr-sun condition">
                                        <span className="temp">{weather.current.feelslike_c}
                                            <span className="deg">0</span><span className="temp-type">C</span>
                                        </span>
                                    </span>
                                </li>
                                <li className="active">
                                    <span className="date">Humidity</span>
                                    <span className="lnr lnr-cloud condition">
                                        <span className="temp">{weather.current.humidity}<span className="temp-type">%</span></span>
                                    </span>
                                </li>
                                <li className="active">
                                    <span className="date">UV Index</span>
                                    <span className="lnr lnr-cloud condition">
                                        <span className="temp">{weather.current.uv}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                ):(<>
                    <div className="bottom">
                        <div className="wrapper">
                            <ul className="forecast">
                                <li className="active">
                                    <span className="date">Feels like</span>
                                    <span className="lnr lnr-sun condition">
                                        <span className="temp">xx
                                            <span className="deg">0</span><span className="temp-type">C</span>
                                        </span>
                                    </span>
                                </li>
                                <li className="active">
                                    <span className="date">Humidity</span>
                                    <span className="lnr lnr-cloud condition">
                                        <span className="temp">xx<span className="temp-type">%</span></span>
                                    </span>
                                </li>
                                <li className="active">
                                    <span className="date">UV Index</span>
                                    <span className="lnr lnr-cloud condition">
                                        <span className="temp"></span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>)}
            </>
        </div>
        // <div className={`weather-card one ${ (weatherQuery.isFetching) ? "fetching":(
        //     (weatherQuery.isFetched)?(condidtionCodeClass):('')
        // )}`}>
        //     <>
        //         <div className="top">
        //             <div className="wrapper">
        //                 { (weatherQuery.isFetching) ? (<div className="fetching-div">Fetching weather...</div>):(<></>)}
        //                 { (weatherQuery.isFetched) ? (
        //                     <>
        //                         <div className="options-nav">
        //                             {/* <a href="#"><span className="lnr lnr-chevron-left"></span></a> */}
        //                             <button className="btn btn-transparent btn-reload" onClick={() => weatherQuery.refetch()}>
        //                                 <Reload className="reload_icon" />
        //                             </button>
        //                         </div>
        //                         <h1 className="heading">{weatherQuery.data?.current.condition.text}</h1>
        //                         <h3 className="location">{weatherQuery.data?.location.name + ', ' + weatherQuery.data?.location.country}</h3>
        //                         <p className="temp">
        //                             <span className="temp-value">{weatherQuery.data?.current.temp_c}</span>
        //                             <span className="deg">0</span>
        //                             <span className="temp-type">C</span>
        //                         </p>
        //                         <div className="poweredByLink">Powered by <a href="https://www.weatherapi.com/" title="Free Weather API">WeatherAPI.com</a></div>
        //                     </>
        //                 ):(<></>)}
                        
        //             </div>
        //         </div>

        //         { (weatherQuery.isFetched) ? (
        //             <div className="bottom">
        //                 <div className="wrapper">
        //                     <ul className="forecast">
        //                         {/* <a href="#"><span className="lnr lnr-chevron-up go-up"></span></a> */}
        //                         <li className="active">
        //                             <span className="date">Feels like</span>
        //                             <span className="lnr lnr-sun condition">
        //                                 <span className="temp">{weatherQuery.data?.current.feelslike_c}
        //                                     <span className="deg">0</span><span className="temp-type">C</span>
        //                                 </span>
        //                             </span>
        //                         </li>
        //                         <li className="active">
        //                             <span className="date">Humidity</span>
        //                             <span className="lnr lnr-cloud condition">
        //                                 <span className="temp">{weatherQuery.data?.current.humidity}<span className="temp-type">%</span></span>
        //                             </span>
        //                         </li>
        //                         <li className="active">
        //                             <span className="date">UV Index</span>
        //                             <span className="lnr lnr-cloud condition">
        //                                 <span className="temp">{weatherQuery.data?.current.uv}</span>
        //                             </span>
        //                         </li>
        //                     </ul>
        //                 </div>
        //             </div>
        //         ):(<></>)}
        //     </>
        // </div>
    );
}
export default WeatherCard;