import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser, checkToken, logout } from "../services/auth.service";
import UserType from '../types/user.type'
import {checkTokenSuccess, checkTokenError} from '../types/auth'

const Subscribe: React.FC = () => {
    let navigate: NavigateFunction = useNavigate();
    const [fetching, setFetching] = useState<boolean>(false)
    const [tokenExpire, setTokenExpire] = useState<string | null>(null)
    const [currentUser, setCurrentUser] = useState<UserType | null>(null)
    

    useEffect(() => {
        const setUserData = async () => {
            setFetching(true)
            const user = getCurrentUser();
            if (user === null){
                navigate('/login')
            }
            else {
                await checkToken().then(async response => {
                    //console.log({checkTokenResponse:response});
                    if ( response as checkTokenError  && response.error?.name === "TokenExpiredError"){
                        logout();
                    }
                    else if ( response as checkTokenSuccess ){
                        const date = new Date(0);
                        date.setUTCSeconds( response.user.exp );
                        const expDateFormated = date.toLocaleString('en-GB')
                        setTokenExpire( expDateFormated )
                        setCurrentUser(user)
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
            setFetching(false)
        }
        setUserData();
    }, []);
    return (
        <div>
            <div>Subscribe</div>
            <div>{currentUser?.firstname}</div>
        </div>
    );
}

export default Subscribe;