import React, { useEffect, useState, MouseEventHandler } from "react"
import { loginUser, getCurrentUser, checkToken, logout } from "../services/auth.service"
import { getLangs, getUserPrefs, updateUserPrefs } from '../services/app.service'
import AuthLinks from './Header/AuthLinks'
import LangsDD from './Header/LangsDD'
import { ReactComponent as Menu } from "../images/svg/list.svg"
import UserType, { languageType } from "../types/user.type"

function Header() {
    const [user, setUser] = useState<UserType | null>(null)
    const [languages, setLanguages] = useState<languageType[] | null>(null)
    const [lang, setLang] = useState<string>('EN')
    // const [langDetails, setLangDetails] = useState<object|null>(null)

    useEffect(() => {
        const getLanguages = async () => {
            const languages = await getLangs()
            setLanguages(languages);
        }
        getLanguages();
        const localUser = getCurrentUser()
        setUser(localUser)
        const userPreferences = getUserPrefs()
        if (userPreferences !== null) setLang(userPreferences.siteLang)
        else setLang('EN')
    }, []);
    
    const handleChangeLang = (event:any) => {
        const langSelected:string = event.target.dataset.lang
        if (lang !== langSelected) {
            setLang(langSelected)
            updateUserPrefs({siteLang:langSelected})
            //Reload page to take effet
            window.location.reload()
        }
    }

    return (
        <header className="header">
            {/* Mobile Menu */}
            <div className="offcanvas offcanvas-start" tabIndex={-1} id="outMenu" aria-labelledby="siteName">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="siteName">News@Userver</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    {(user)?(
                        <>
                            <div>Hello {user.firstname}</div>
                            <AuthLinks logged_in={true} offcanvas={true} logout={logout}/>
                        </>
                    ):(
                        <div className="col-4 header-links-right">
                            <AuthLinks logged_in={false} offcanvas={true} logout={logout}/>
                        </div>
                    )}
                    
                    <a className="link-secondary" data-bs-dismiss="offcanvas" href="/subscribe">Subscribe</a>
                    {(languages)?(<LangsDD langs={ languages } selected={lang} action={handleChangeLang} />):('')}
                </div>
            </div>

            {/* Desktop Menu */}
            <div className="row header_wrapper">
                <div className="col-4 pt-1 header-links-left">
                    <a className="link-secondary" href="/subscribe">Subscribe</a>
                    <a className="menuIcon link-secondary" data-bs-toggle="offcanvas" href="#outMenu" role="button" aria-controls="outMenu">
                        <Menu className="menu_icon" />
                    </a>
                </div>
                <div className="col-4 text-center logo-left">
                    <a className="header-site-logo text-dark" href="/">News@Userver</a>
                </div>
                <div className="col-4 header-links-right">
                    {(languages)?(<LangsDD langs={ languages } selected={lang} action={handleChangeLang} />):('')}
                    <AuthLinks logged_in={(user)?true:false} offcanvas={false} logout={logout}/>
                </div>
            </div>
            <div id="topOfPage"></div>
        </header>
    );
}

export default Header;