import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { getCurrentUser, checkToken, logout } from "../services/auth.service";
import UserType from '../types/user.type'
import {checkTokenSuccess, checkTokenError} from '../types/auth'
import settings from "../config/settings.json";

const getUsersQuery = async (token:string) => {
    // const BASE_URL = 'http://localhost:3123/api/users'
    const BASE_URL = settings.API_SERVER.URL + settings.API_SERVER.USERS
    const axiosAuthInstance = axios.create({
        baseURL : BASE_URL,
        headers: {
            Authorization   : `Bearer ${token}`,
            'Content-Type'  : 'application/json',
        }
    });
    const users = await axiosAuthInstance.get(BASE_URL).then(
        response => {
            return response.data;
        }
    ).catch(
        error => {
            console.log({error});
            return null;
        }
    );
    return users;
}

const Adminpage: React.FC = () => {
    let navigate: NavigateFunction = useNavigate();
    const [fetching, setFetching] = useState<boolean>(false)
    const [currentUser, setCurrentUser] = useState<UserType | null>(null)
    const [users, setUsers] = useState<Array<UserType> | null>(null);
    
    useEffect(() => {
        const setUserGetList = async () => {
            setFetching(true)
            const user = getCurrentUser();
            if (user === null){
                navigate('/login')
            }
            else {
                await checkToken().then(async response => {
                    if ( response as checkTokenError  && response.error?.name === "TokenExpiredError"){
                        logout();
                    }
                    else if ( response as checkTokenSuccess ){
                        setCurrentUser(user)
                        const userList:Array<UserType> = await getUsersQuery(user.token);
                        console.log({userList});
                        setUsers(userList)
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
            setFetching(false)
        }
        setUserGetList();

    }, []);
    
    return (
        <>
            <div className="adminpage_container">
                <div className="bg-light rounded-3 hp-first-row">
                    <div className="container-fluid py-5">
                        <h3 className="mb-3">Users in Database</h3>
                        { (fetching) ? (<div className="my-2 fetching-div">Fetching users...</div>):('')}
                        {!fetching && currentUser ? (
                        <>
                            {users === null && <>Loading users...</>}
                            {users && 
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Firstname</th>
                                            <th>Lastname</th>
                                            <th>email</th>
                                            <th>created on</th>
                                            <th>updated on</th>
                                            <th>Roles</th>
                                            <th>Activated</th>
                                            <th>Edit Btn</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user:any, key:any) => 
                                            <tr key={key} id={"user_id_" + user._id}>
                                                <th scope="row">{user._id}</th>
                                                <td>{user.firstname}</td>
                                                <td>{user.lastname}</td>
                                                <td>{user.email}</td>
                                                <td>{user.createdAt}</td>
                                                <td>{user.updatedAt}</td>
                                                <td>Not done</td>
                                                <td>Not done</td>
                                                <td>Do edit</td>
                                            </tr>
                                        )} 
                                    </tbody>
                                </table>
                            }
                            
                        </>):(
                        <>
                            Loading
                        </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Adminpage;