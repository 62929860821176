export const fixText = (text:string) => {
    return text
    .replaceAll('&#32;', " ")
    .replaceAll('&#39;', "'")
    .replaceAll('&#160;', " ")
    .replaceAll('&#8217;', "'")
    .replaceAll('&#8230;', '...')
    .replaceAll('&#8212;', '—')
    .replaceAll('&#8211;', '–')
    .replaceAll('&#8220;', '"')
    .replaceAll('&#8221;', '"')
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&eacute;', 'é')
    .replaceAll('&egrave;', 'è')
    .replaceAll('&agrave;', 'à')
    .replaceAll('&acirc;', 'â')
    .replaceAll('&ecirc;', 'ê')
    .replaceAll('&ucirc;', 'û')
    .replaceAll('&ocirc;', 'ô')
    .replaceAll('&icirc;', 'î')
    .replaceAll('[link]', '')
    .replaceAll('[comments]', '')
}


const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]
export const fixDate = (date:string) => {
    const newDate = new Date(date.split('T')[0]);
    return newDate.toLocaleDateString('en-GB');
}
export const objectLength = (object:object) => {
    return Object.keys(object).length;
}
/*
const currentDate = new Date();

const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

console.log(currentDate.toLocaleDateString('de-DE', options));
//Freitag, 2. Juli 2021

console.log(currentDate.toLocaleDateString('ar-EG', options))
// الجمعة، ٢ يوليو ٢٠٢١

console.log(currentDate.toLocaleDateString('en-us', options));
//Friday, Jul 2, 2021
*/