import axios from 'axios'
import settings from "../config/settings.json";
// import { weatherAPIDataType, geolocationDataType } from '../components/types/WeatherAPI'

// export const getLoginUser = async <T>(
//     email: string,
//     password: string,
//     rememberMe:boolean
// )
// : Promise<T> => {
//     const SIGNUP_URL = settings.API_SERVER.URL + settings.API_SERVER.SIGNIN
//     // const SIGNUP_URL = "http://localhost:3123/api/login"
//     console.log('SIGNUP_URL', SIGNUP_URL);
//     const res = await fetch(SIGNUP_URL, {
//         method: 'Post',
//         headers: {
//             'Content-type': 'application/json',
//             'accept': 'application/json'
//         },
//         body: JSON.stringify({ email, password, rememberMe })
//     });
//     console.log({res});
//     return await res.json();
// }
// export const registerUser = async <T>(
//     firstname: string,
//     lastname: string,
//     email: string,
//     password: string
// )
// : Promise<T> => {
//     const SIGNUP_URL = settings.API_SERVER.URL + settings.API_SERVER.SIGNUP
//     const res = await fetch(SIGNUP_URL, {
//         method: 'Post',
//         headers: {
//             'Content-type': 'application/json'
//         },
//         body: JSON.stringify({ firstname, lastname, email, password })
//     });
//     return await res.json();
// }

// export const getNews = async <T>(): Promise<T> => {
//     const url = "https://node.userv.info/news/all?limit=5";
//     const res = await fetch(url, {
//         method: 'GET',
//         headers: {
//             'Content-type': 'application/json'
//         },
//     });
//     return await res.json();
// }
export const testAxios = async() =>{
    const BASE_URL = 'https://node.userv.info/news/byid/1234'
    const axiosAuthInstance = axios.create({
        baseURL : BASE_URL,
        headers: {
            // Authorization   : `Bearer ${accessToken}`,
            'Content-Type'  : 'application/json',
            // 'accept'        : 'application/json'
        }
    });
    const axiosGetReq = await axiosAuthInstance.get(BASE_URL).then(
        response => {
            console.log('axiosAuthInstance get :', response.data);
            return response.data;
        }
    ).catch(
        error => {
            console.log(error);
        }
    );
    // if (axiosGetReq == undefined) console.log('AuthRequest is undefined, reload?');
    console.log('axiosGetReq', axiosGetReq);
    return axiosGetReq
}
export const getNewsData = async (keysearch:string | undefined, lang:string | undefined) => {
    if (keysearch === undefined) keysearch = 'global';
    if (lang === undefined) lang = 'en';
    const fetchURL = settings.API_SERVER.URL + settings.API_SERVER.BASE_ARTICLES + keysearch + '?lang=' + lang;
    // console.log({getNewsData_URL:fetchURL});
    // console.log({keysearch});
    try {
        const apiResponse = await fetch(fetchURL);
        const json = await apiResponse.json();
        return json;
    } catch (error) {
        // console.log({getNewsData:error});
        return error;
    }
}

export const getWeatherFromGPSAxios = async<T>(coord:string): Promise<T> => {
    const APISettings = settings.API_WEATHERAPI
    const weatherAPI_URL = 
        APISettings.URL + 
        APISettings.current + 
        APISettings.KEYSlug + 
        APISettings.KEY + 
        APISettings.QUERY + 
        coord
    console.log("getweather url:",weatherAPI_URL);
    const axiosGetReq = await axios.get(weatherAPI_URL)
        .then(response => {
            console.log('getWeatherFromGPSAxios get :', response.data);
            return response.data;
        }
        ).catch(
            error => {
                console.log(error);
            }
        );
    return axiosGetReq
}
export const getWeatherFromGPS = async<T>(coord:string): Promise<T> => {
    const APISettings = settings.API_WEATHERAPI
    const weatherAPI_URL = 
        APISettings.URL + 
        APISettings.current + 
        APISettings.KEYSlug + 
        APISettings.KEY + 
        APISettings.QUERY + 
        coord
    console.log("getweather url:",weatherAPI_URL);
    const res = await fetch(weatherAPI_URL, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });
    return await res.json();
}
export const getWeatherData = async<T>(): Promise<T> => {
    const APISettings = settings.API_WEATHERAPI
    const location_URL = settings.GEOLOC_API.URL + settings.GEOLOC_API.APIKEY
    console.log("getUserLocation url:", location_URL);


    // const location = await axios.get(location_URL)
    //     .then(response => {
    //         console.log('axiosGetReq get :', response.data);
    //         return response.data;
    //     }
    //     ).catch(
    //         error => {
    //             console.log(error);
    //         }
    //     );
    // const weatherAPI_URL = 
    //     APISettings.URL + 
    //     APISettings.current + 
    //     APISettings.KEYSlug + 
    //     APISettings.KEY + 
    //     APISettings.QUERY +
    //     location.latitude + ',' + location.longitude
    // console.log("getweather url:", weatherAPI_URL);
    // const weatherResponse = await axios.get(weatherAPI_URL)
    //     .then(response => {
    //         console.log('axiosGetReq get :', response.data);
    //         return response.data;
    //     }
    //     ).catch(
    //         error => {
    //             console.log(error);
    //         }
    //     );
    // return weatherResponse

    const location = await axios.get(location_URL)
    .then(async (response:any) => {
        console.log('axiosGetReq get :', response.data);

        // return response.data;
        const weatherAPI_URL:string = 
            APISettings.URL + 
            APISettings.current + 
            APISettings.KEYSlug + 
            APISettings.KEY + 
            APISettings.QUERY +
            response.data.latitude + ',' + response.data.longitude
        console.log("getweather url:", weatherAPI_URL);
        const weatherResponse = await axios.get(weatherAPI_URL)
            .then(response => {
                console.log('axiosGetReq get :', response.data);
                return response.data;
            }
            ).catch(
                error => {
                    console.log(error);
                }
            );
        return weatherResponse
    }
    ).catch(
        error => {
            console.log(error);
        }
    );
    return location





    // const res = await fetch(location_URL).then(
    //     async<weatherAPIDataType>(response:any) => {
    //         console.log({weatherResponse:response});
    //         const location:geolocationDataType = await response.json()
            
    //         console.log({locationResponse:location});

    //         const APISettings = settings.API_WEATHERAPI
    //         const weatherAPI_URL = 
    //             APISettings.URL + 
    //             APISettings.current + 
    //             APISettings.KEYSlug + 
    //             APISettings.KEY + 
    //             APISettings.QUERY +
    //             location.latitude + ',' + location.longitude
    //         console.log("getweather url:", weatherAPI_URL);
    //         const weatherResponse = await fetch(weatherAPI_URL, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-type': 'application/json'
    //             },
    //         });
    //         return await weatherResponse.json();
    //         // return response
    //     }
    // );
    // return res;




    // const locationData = await loacationResponse.json();
    
    // const lat:number = locationData.latitude
    // const long:number = locationData.longitude
    // // const lat = 13.75
    // // const long = 100.4667
    // const APISettings = settings.API_WEATHERAPI
    // const weatherAPI_URL = 
    //     APISettings.URL + 
    //     APISettings.current + 
    //     APISettings.KEYSlug + 
    //     APISettings.KEY + 
    //     APISettings.QUERY + 
    //     lat + ',' + long
    // console.log("getweather url:",weatherAPI_URL);
    // const weatherResponse = await fetch(weatherAPI_URL, {
    //     method: 'GET',
    //     headers: {
    //         'Content-type': 'application/json'
    //     },
    // });
    // return await weatherResponse.json();
}
export const getOpenWeatherData = async (location:string) => {
    //const fetchURL = settings.API_WEATHER.URL + settings.API_WEATHER.KEYSlug + settings.API_WEATHER.KEY + settings.API_WEATHER.QUERY + location;
    const fetchURL = settings.API_OPENWEATHER.URL + settings.API_OPENWEATHER.KEYSlug  + settings.API_OPENWEATHER.KEY + '&units=metric'+ settings.API_OPENWEATHER.QUERY + location;
    // console.log({getWeatherDataURL:fetchURL});
    try {
        const apiResponse = await fetch(fetchURL);
        const json = await apiResponse.json();
        return json;
    } catch (error) {
        console.log({getWeatherData:error});
        return error;
    }
}
export const getGeoLocUser = async () => {
    try {
        const apiResponse = await fetch(settings.GEOLOC_API.URL + settings.GEOLOC_API.APIKEY);
        const json = await apiResponse.json();
        return await json;
    } catch (error) {
        console.log({getGeoLocUser:error});
        return error;
    }
}
export const getUserLocation = async<T>(): Promise<T> => {
    const url = settings.GEOLOC_API.URL + settings.GEOLOC_API.APIKEY
    console.log("getUserLocation url:", url);
    const res = await fetch(url);
    return await res.json();
}
export const getUserLocationByIp = async <T>(ip:any): Promise<T> => {
    const url = settings.IP_TO_LOCATION.URL + ip
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'apikey' : settings.IP_TO_LOCATION.KEY
        },
    });
    return await res.json();
}


const dummyData = {
    err: 0,
    message: "",
    articles: {
    top: {
    count: 1,
    message: "",
    err: 0,
    rows: [
    {
    id: 326,
    author: "Sgt. 1st Class Jim Heuston",
    title: "Ark. TAG Meets With The Guatemala Joint Chief of Staff [Image 3 of 7]",
    description: "Maj. Gen. Jonathan M. Stubbs, the adjutant general of the Arkansas National Guard, held an office call with the Guatemala Joint Chief of Staff, Brig. Gen. Wiliam Arnulfo Lopez Chay in a meeting meant to emphasize Arkansas’ commitment to the state partnership with Guatemala.The leaders focused their discussion on mitigating common threats from crime to climate change, increasing regional security, and strengthening the partnership through improved capability development. The state partnership with the Guatemalan military continues to support subject matter expert exchanges to strengthen...",
    url: "https://www.dvidshub.net/image/7715721/ark-tag-meets-with-guatemala-joint-chief-staff",
    source: "dvidshub",
    image: "https://cdn.dvidshub.net/media/thumbs/photos/2303/7715721/250x179_q75.jpg",
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-04-01T00:04:20.000Z",
    valuable: 3,
    createdAt: "2023-04-01T03:57:00.000Z",
    updatedAt: "2023-04-01T03:57:00.000Z"
    }
    ]
    },
    mid: {
    count: 2,
    err: 0,
    message: "",
    rows: [
    {
    id: 325,
    author: "Postmedia News",
    title: "TAXED TO THE MAX: Federal carbon tax up 30% April 1",
    description: "WATCH BELOW: The Trudeau government claims 80% of Canadians paying the federal carbon tax end up richer because of climate action incentive payments. But Yves Giroux, the Parliamentary Budget Officer says in fact,  60% end up poorer as Sun Media political columnist Lorrie Goldstein explains. What do YOU think? Tweet and Facebook us! And don’t forget to [&#8230;]",
    url: "https://torontosun.com/news/taxed-to-the-max-federal-carbon-tax-up-30-april-1",
    source: "torontosun",
    image: null,
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-03-31T22:28:08.000Z",
    valuable: 2,
    createdAt: "2023-04-01T03:57:00.000Z",
    updatedAt: "2023-04-01T03:57:00.000Z"
    },
    {
    id: 272,
    author: "AP News",
    title: "Climate protesters who stormed British Grand Prix avoid jail",
    description: "Six climate change activists were spared prison sentences for storming last year’s British Grand Prix Formula 1 race in one of a series of disruptive protests against fossil fuels",
    url: "https://www.mymotherlode.com/news/europe/3025948/climate-protesters-who-stormed-british-grand-prix-avoid-jail.html",
    source: "mymotherlode",
    image: null,
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-03-31T19:36:01.000Z",
    valuable: 2,
    createdAt: "2023-04-01T03:56:59.000Z",
    updatedAt: "2023-04-01T03:56:59.000Z"
    }
    ]
    },
    bot: {
    count: 5,
    err: 0,
    message: "",
    rows: [
    {
    id: 285,
    author: "Sgt. 1st Class Jim Heuston",
    title: "Ark. TAG Meets With The Guatemala Joint Chief of Staff [Image 1 of 7]",
    description: "Maj. Gen. Jonathan M. Stubbs, the adjutant general of the Arkansas National Guard, held an office call with the Guatemala Joint Chief of Staff, Brig. Gen. Wiliam Arnulfo Lopez Chay in a meeting meant to emphasize Arkansas’ commitment to the state partnership with Guatemala.The leaders focused their discussion on mitigating common threats from crime to climate change, increasing regional security, and strengthening the partnership through improved capability development. The state partnership with the Guatemalan military continues to support subject matter expert exchanges to strengthen...",
    url: "https://www.dvidshub.net/image/7715684/ark-tag-meets-with-guatemala-joint-chief-staff",
    source: "dvidshub",
    image: "https://cdn.dvidshub.net/media/thumbs/photos/2303/7715684/250x179_q75.jpg",
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-04-01T00:04:16.000Z",
    valuable: 3,
    createdAt: "2023-04-01T03:56:59.000Z",
    updatedAt: "2023-04-01T03:56:59.000Z"
    },
    {
    id: 342,
    author: "Letters To The Editor",
    title: "Letters: Oakland cops | Bad proposal | Climate change | Liberal parents | Careful compromise",
    description: "East Bay Times Letters to the Editor for April 2, 2023",
    url: "https://www.mercurynews.com/2023/03/31/letters-1211/",
    source: "mercurynews",
    image: "https://www.mercurynews.com/wp-content/uploads/2023/03/OAK-OPD-1202-022-1-1.jpg?w=1400px&strip=all",
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-03-31T23:30:48.000Z",
    valuable: 3,
    createdAt: "2023-04-01T03:57:00.000Z",
    updatedAt: "2023-04-01T03:57:00.000Z"
    },
    {
    id: 273,
    author: "wtopstaff",
    title: "Climate protesters who stormed British Grand Prix avoid jail",
    description: "LONDON (AP) — Six climate change activists who stormed the track at last year’s British Grand Prix Formula 1 race&#8230;",
    url: "https://wtop.com/world/2023/03/climate-protesters-who-stormed-british-grand-prix-avoid-jail/",
    source: "wtop",
    image: "https://wtop.com/wp-content/uploads/2022/11/Climate_Britain_Protests_70611-1024x683.jpg",
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-03-31T19:27:08.000Z",
    valuable: 3,
    createdAt: "2023-04-01T03:56:59.000Z",
    updatedAt: "2023-04-01T03:56:59.000Z"
    },
    {
    id: 323,
    author: "Jim Vinoski, Contributor",
    title: "Want Clean Energy? We’ll Need More Fossil Fuels",
    description: "Despite strident demands by climate activists to cease use of oil and gas right now, the reality is that every plausible future scenario means we'll need more hydrocarbons, not less.",
    url: "https://www.forbes.com/sites/jimvinoski/2023/03/31/want-clean-energy-well-need-more-fossil-fuels/",
    source: "Forbes",
    image: "https://imageio.forbes.com/specials-images/imageserve/6426df8395b37da3168cd6f1/0x0.jpg?width=960",
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-03-31T14:00:26.000Z",
    valuable: 3,
    createdAt: "2023-04-01T03:57:00.000Z",
    updatedAt: "2023-04-01T03:57:00.000Z"
    },
    {
    id: 302,
    author: "Scotsman comment",
    title: "First Minister's Questions: Repeated disruption by climate change protesters is anti-democratic and counterproductive – Scotsman comment",
    description: "First Minister’s Questions is Scottish democracy’s flagship event.",
    url: "https://www.scotsman.com/news/opinion/columnists/first-ministers-questions-repeated-disruption-by-climate-change-protesters-is-anti-democratic-and-counterproductive-scotsman-comment-4086426",
    source: "scotsman",
    image: "https://www.scotsman.com/webimg/b25lY21zOmVjNDRjZTM1LWRjZmMtNDg0Yi04NDQ3LTE2ZTUzN2QyNzRhMjpiODJjYWU4Mi0wYTFiLTQ5NTMtYTRmNy0wMDg0YzdhMjkyZjI=.jpg?width=1200&auto=webp",
    category: "general",
    language: "en",
    country: "us",
    keysearch: "climate",
    published_at: "2023-03-31T11:30:00.000Z",
    valuable: 3,
    createdAt: "2023-04-01T03:57:00.000Z",
    updatedAt: "2023-04-01T03:57:00.000Z"
    }
    ]
    }
    }
    };