import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import WeatherCard from '../components/WeatherCard';
import EditorialCard from '../components/EditorialCard';
import settings from "../config/settings.json";

const Homepage: React.FC = () => {
    let navigate: NavigateFunction = useNavigate();
    const goToSubscribe = () =>{
        navigate("/subscribe");
    }
    return (
        <div className="homepage_container">
            <div className="p-5 mb-4 bg-light rounded-3 hp-first-row">
                <div className="container-fluid py-5">
                    <h1 className="display-5 fw-bold">Filtered News</h1>
                    <p className="col-md-8 fs-4">We filter news for you, get all you want to know, select what's you're interested in, and let us do the work for you. To recieve your personal newsletter, just subscribe and check boxes.</p>
                    <button onClick={goToSubscribe} className="btn btn-primary btn-lg" type="button">Subscribe here</button>
                </div>
            </div>
            <div className='row hp-second-row'>
                <div className='col-lg-8 mb-4'>
                    <div className="row editorialcards">
                        {settings.TopicLinksNames.map( (topic) => <EditorialCard key={topic.id} {...topic} />)}
                    </div>
                </div>
                {/* <div className='col-lg-8 mb-4 row coolcards'>
                    <CoolCard color={"gr-1"} title={"GLOBAL NEWS"} text={"Visual communication and problem-solving"} link={"/news"} />
                    <CoolCard color={"gr-2"} title={"ARTIFICIAL INTELLIGENCE"} text={"Visual communication and problem-solving"} link={"/news/ai"} />
                    <CoolCard color={"gr-3"} title={"CLIMATE CRISIS"} text={"Visual communication and problem-solving"} link={"/news/climate"} />
                    <CoolCard color={"gr-1"} title={"FINANCE"} text={"Visual communication and problem-solving"} link={"/news/finance"} />
                    <CoolCard color={"gr-2"} title={"ENTERTAINMENT"} text={"Visual communication and problem-solving"} link={"/news/entertainment"} />
                    <CoolCard color={"gr-3"} title={"SPORTS"} text={"Visual communication and problem-solving"} link={"/news/sports"} />
                    <CoolCard color={"gr-1"} title={"SCIENCE"} text={"Visual communication and problem-solving"} link={"/news/science"} />
                </div> */}
                <div className='col-sm-6 col-lg-4 mb-4 column'>
                    <WeatherCard />
                </div>
                
            </div>
        </div>
    );
}
export default Homepage;