import axios from 'axios'
import Cookies from 'universal-cookie';
import settings from "../config/settings.json";
const cookies = new Cookies();

interface userPrefsType {
    siteLang:string
}

export const getLocation = async () => {
    const location_URL = settings.GEOLOC_API.URL + settings.GEOLOC_API.APIKEY
    await new Promise((r) => setTimeout(r, 500))
    const axiosInstance = axios.create({
        baseURL: location_URL, 
        headers: {
            'Content-Type'  : 'application/json',
        }
    })
    const { data } = await axiosInstance.get(location_URL)
    return data
}
export const getLangs = async () => {
    const URL = settings.API_SERVER.URL + settings.API_SERVER.LANG
    await new Promise((r) => setTimeout(r, 500))
    const axiosInstance = axios.create({
        baseURL: URL, 
        headers: {
            'Content-Type'  : 'application/json',
        }
    })
    const { data } = await axiosInstance.get(URL)
    return data
}
export const retrieveLocation = () => {
    const coord = localStorage.getItem("userlocation");
    if (coord) return JSON.parse(coord);
    return null;
}
export const updateLocation = async (update:any) => {
    const coord = await retrieveLocation()
    if (coord !== null){
        for (const field in update){
            coord[field] = update[field]
        }
    }
    localStorage.setItem("userlocation", JSON.stringify(coord))
}

/* COOKIES MANAGEMENT */
//get
export const getUserPrefs = () => {
    // console.log('get cookies');
    const userPrefs = cookies.get('userPrefs')
    // console.log({cookiePref:cookies.get('userPrefs')});
    return (userPrefs)?userPrefs:null;
}
//set
export const setUserPrefs = (prefs:object) => {
    // console.log('set cookies');
    // console.log({prefs});
    cookies.set('userPrefs', prefs, { path: '/' });
}
//update
export const updateUserPrefs = (prefs:userPrefsType ) => {
    // console.log('update cookies');
    let oldPrefs = getUserPrefs();
    let newPrefs = { ...oldPrefs, ...prefs };
    cookies.set('userPrefs', newPrefs, { path: '/' });
    return cookies.get('userPrefs');
}
//delete
export const cleanCookies = () =>{
    // console.log('remove cookies');
    cookies.remove('userPrefs');
}