import settings from "../config/settings.json";
// import { cleanCookies } from './app.service'

const AUTH_URL          = settings.API_SERVER.URL;
const SIGNIN_URL        = AUTH_URL + settings.API_SERVER.SIGNIN
const SIGNUP_URL        = AUTH_URL + settings.API_SERVER.SIGNUP
const CHECKTOKEN_URL    = AUTH_URL + settings.API_SERVER.CHECKTOKEN

/* dev urls */
// const SIGNIN_URL_LOCAL      = settings.DEV_SERVER.LOGIN
// const SIGNUP_URL_LOCAL      = settings.DEV_SERVER.REGISTER
const CHECKTOKEN_URL_LOCAL  = settings.DEV_SERVER.CHECK

export const loginUser = async <T>( email: string, password: string, rememberMe:boolean ): Promise<T> => {
    const res = await fetch(SIGNIN_URL, {
        method: 'Post',
        headers: {
            'Content-type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({ email, password, rememberMe })
    });
    return await res.json();
}

export const registerUser = async <T>( firstname: string, lastname: string, email: string, password: string, terms:boolean ): Promise<T> => {
    const res = await fetch(SIGNUP_URL, {
        method: 'Post',
        headers: {
            'Content-type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({ firstname, lastname, email, password, terms })
    });
    return await res.json();
}
export const checkToken = async () => {
    const token = getCurrentUser().token
    const res = await fetch(CHECKTOKEN_URL, {
        method: 'Get',
        headers: {
            Authorization   : `Bearer ${token}`,
            'Content-type': 'application/json',
            'accept': 'application/json'
        },
    });
    return await res.json();
}
export const logout = () => {
    // cleanCookies()
    localStorage.removeItem("user");
    window.location.reload();
};
export const getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
};

const manageUserStatus = () => {
    console.log('plop');
}

// export const login = (username: string, password: string, rememberMe:boolean) => {
//     return axios
//     .post(SIGNIN_URL, {
//         username,
//         password,
//         rememberMe
//     })
//     .then((response) => {
//         console.log('data:', response.data);
//         console.log('user:', response.data.user);
//         if (response.data.user.token) {
//             localStorage.setItem("user", JSON.stringify(response.data));
//         }
//         return response.data;
//     });
// };

// export const register = ( firstname: string, lastname: string, email: string, password: string ) => {
//     return axios.post(SIGNUP_URL, {
//         firstname,
//         lastname,
//         email,
//         password,
//     });
// };