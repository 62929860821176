import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { loginUser, getCurrentUser, checkToken, logout } from "../../services/auth.service";
import FormInput from '../../components/form-input/form-input';
import { LoginResponse } from '../../types/services'
import UserType from '../../types/user.type'
import { objectLength } from '../../utils/tools'
const loginFormFields = {
    email: '',
    password: '',
    rememberMe: false,
}

function Login() {
    // react hooks
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<UserType | null>()
    const [formFields, setFormFields] = useState(loginFormFields)
    const [rememberMeCheck, setRememberMeCheck] = useState(false)
    const [errorLoginMessage, setErrorLoginMessage] = useState<string | null>(null)
    const { email, password, rememberMe } = formFields
    let navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        console.log('Login UserEffect')
        if (getCurrentUser() !== null){
            const verifyUser = async() =>{
                const res = await checkToken();
                console.log({loginRes:res});
                if (res.error.name === "TokenExpiredError"){
                    logout();
                }
            }
            verifyUser();
            navigate("/mypage");
            window.location.reload();
        }
        // console.log('user null');
        // navigate("/login");
        // window.location.reload();
    }, []);

    const resetFormFields = () => {
        setRememberMeCheck(false)
        return (
            setFormFields(loginFormFields)
        );
    }
    // handle input changes
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target
        // console.log("input name:", name);
        // console.log("input value:", value);
        if (name === 'rememberMe') {
            setRememberMeCheck(!rememberMeCheck);
            formFields.rememberMe = !rememberMeCheck
        }
        else setFormFields({...formFields, [name]: value })
    }
  
    const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setErrorLoginMessage(null)
        setLoading(true)
        try {
            const res:LoginResponse = await loginUser(email, password, rememberMe)
            console.log({UserLoggedIn:res});
            const user:UserType = res.user;
            
            if ( res.error === 1 ) setErrorLoginMessage(res.message);
            if ( res.error === 0 ) {
                setUser(user);
                if (user.token){
                    localStorage.setItem("user", JSON.stringify(user));
                    navigate("/mypage");
                    window.location.reload();
                }
            }
            
        }
        catch (error:any) {
            console.log(objectLength(error));
            setErrorLoginMessage(objectLength(error) + ' ' + error.message);
        }
        setLoading(false)
    };
    
    // const reload = () => {
    //     setUser(null);
    //     resetFormFields()
    // };

    return (
        <> 
        {/* {(loading)?("someone"):('nobody')} */}
        {/* {!loading && user && <>we have a user: {user.firstname}</>} */}
            <div className="mt-4 text-center"><h3 className="h3 mb-3">Wellcome{user && <> Back! {user.firstname}</>}</h3></div>
            { (user) ? (<>
                <div className="container">
                    <div className="mt-4 text-center">You are already logged in</div>
                </div>
            </>):(
                <div className="form-signin auth-form">
                    <form  onSubmit={handleLogin}>
                        
                        <h3 className="h4 mb-3">Sign in to your account</h3>
                        {errorLoginMessage && <>
                            <div className="alert alert-danger" role="alert">
                                {errorLoginMessage}
                            </div>
                        </>}
                        <FormInput
                            id="floatingEmailInput"
                            className="form-control"
                            label="Email"
                            type="email"
                            required
                            name="email"
                            value={email}
                            onChange={handleChange}
                            placeholder="name@example.com"
                        />
                        <FormInput
                            id="floatingPasswordInput"
                            className="form-control" 
                            label="Password"
                            type='password'
                            required
                            name='password'
                            value={password}
                            onChange={handleChange}
                            placeholder="Password"
                        />

                        <div className="checkbox mb-3">
                            <label>
                                <input type="checkbox" checked={rememberMeCheck} name="rememberMe" onChange={handleChange} /> Remember me
                            </label>
                        </div>
                        <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                        {(loading)?(<div>Loading...</div>):("")}
                    </form>
                    <div className="my-2">
                        Need an Account? <Link to="/register">Sign Up</Link>
                    </div>
                </div>
            )}
        </>
    );
}

export default Login;
