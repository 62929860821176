import React from "react";
import { ArticleType } from '../types/ArticleTypes'
import { fixText, fixDate } from "../../utils/tools";
export interface BotArticleProps {
    articleValues: ArticleType
}
function BotArticle(props:BotArticleProps) {
    const article       = props.articleValues
    const pubDate       = fixDate(article.published_at)
    const title         = fixText(article.title)
    const description   = fixText(article.description)
    return (
        <article className="postcard light blue third-bot-article" data-articleid={article.id}>
            {/* <a className="postcard__img_link" href="#"> */}
                <img className="postcard__img" src={article.image} alt={title} />
            {/* </a> */}
            <div className="postcard__text t-dark">
                <h1 className="postcard__title blue">
                    <a href={article.url} target="_blank" rel="noopener noreferrer nofollow">{title}</a>
                </h1>
                <div className="postcard__subtitle small">
                    <time dateTime="2020-05-25 12:00:00">
                        <i className="fas fa-calendar-alt mr-2"></i>{pubDate}
                    </time>
                </div>
                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">{description}</div>
                <ul className="postcard__tagbox">
                    <li className="tag__item play">
                        <i className="fas fa-tag mr-2"></i>Author : {article.author}
                    </li>
                    <li className="tag__item"><i className="fas fa-tag mr-2"></i>Source : {article.source}</li>
                    <li className="tag__item play blue">
                        <a href={article.url} target="_blank" rel="noopener noreferrer nofollow">
                            <i className="fas fa-play mr-2"></i>
                            Continue reading
                        </a>
                    </li>
                </ul>
                <span className="art_id">id: {article.id}</span>
            </div>
        </article>
    )
}
export default BotArticle;

