import React, { useEffect, useState } from "react";
import Header from './components/Header';
import TopicsNavigation from './components/Header/TopicsNavigation'
import Footer from './components/Footer';
import Layout from './components/Layout';
import { getLangs, getUserPrefs, setUserPrefs } from './services/app.service'
import { languageType } from "./types/user.type";

function App() {
    // const [languages, setLanguages] = useState<languageType[] | null>(null)
    // const [langSelected, setLangSelected] = useState<string>('EN')
    useEffect(() => {
        const makePrefs = () => {
            const prefs = getUserPrefs();
            if (prefs === null){
                setUserPrefs({siteLang:'EN'})
            }
            else{
                setUserPrefs({siteLang:prefs.siteLang})
            }
        }
        makePrefs();
    }, []);
    
    return (
        <>  
            <Header />
            {/* Sub Menu for topics/categories */}
            <TopicsNavigation cN="nav-scroller mb-2" />
            <div id="page_wrapper">
                <div id="page_content">
                    <Layout />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default App;
