import React from "react";
import { ArticleType } from '../types/ArticleTypes'
import { fixText, fixDate } from "../../utils/tools";
export interface TopArticleProps {
    articleValues: ArticleType
}
function TopArticle(props:TopArticleProps) {
    const article       = props.articleValues
    const pubDate       = fixDate(article.published_at)
    const title         = fixText(article.title)
    const description   = fixText(article.description)
    return (
        <article className="p-4 p-md-5 mb-4 text-white rounded bg-dark first-top-article" data-articleid={article.id}>
            <div className="row x-0">
                <div className='col-md-6'>
                    <h1 className="display-4 fst-italic">{title}</h1>
                    <div className="">Source: {article.source}</div>
                    <div className="">Author: {article.author}</div>
                    <div className="">{pubDate}</div>
                    <p className="lead my-3 desc-article">{description}</p>
                    <p className="lead mb-0">
                        <a href={article.url} target="_blank" rel="noreferrer" className="text-white fw-bold">Continue reading...</a>
                    </p>
                    <span className="art_id">id: {article.id}</span>
                </div>
                <div className='col-md-6 image_wrapper'>
                    <img className="postcard__img" src={article.image} alt={title} />
                </div>
            </div>
        </article>
    )
}
export default TopArticle;

