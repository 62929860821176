import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import settings from "../../config/settings.json";

interface TopicsNavigationProps {
    cN: string;
}
interface navType {
    id:   number,
    name: string,
    link: string
}
function TopicsNavigation(props:TopicsNavigationProps) {
    const [selectedSubMenu, setSelectedSubMenu] = useState<HTMLAnchorElement>();
    const location  = useLocation();
    useEffect(() => {
        /* filter in topics listed item with location path */
        const topicSelected = settings.TopicLinksNames.filter(topic => topic.link === location.pathname);
        // console.log({topicSelected});
        /* We have at least one*/
        if (topicSelected.length > 0){
            /* select  the one that is selected from location path */
            const topicMenuID = document.getElementById('submenu_' + topicSelected[0].name) as HTMLAnchorElement | null
            // console.log({topicMenuID});
            if ( topicMenuID !== null ){
                setSelectedSubMenu(topicMenuID);
                topicMenuID.classList.add("text-decoration-none");
                topicMenuID.scrollIntoView(true);
            }
        }
    }, []);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        console.log({selectedSubMenu});
        // selectedSubMenu.classList.add("text-decoration-none");
        // if (selectedSubMenu == event.currentTarget) console.log('Same Menu');
        if (selectedSubMenu === event.currentTarget) {
            event.preventDefault();
            
        }
    }
    return (
        <div className={props.cN}>
            <nav className="nav d-flex justify-content-between">
                {settings.TopicLinksNames.map( (nav:navType) => 
                    <a key={nav.id} id={'submenu_' + nav.name} href={nav.link} onClick={handleClick} className="subtopic p-2 link-secondary">{nav.name}</a>
                )}
            </nav>
        </div>
    )
}
export default TopicsNavigation;

//<Link to={nav.link} key={nav.id} id={'submenu_' + nav.name} onClick={handleClick} className="subtopic p-2 link-secondary">{nav.name}</Link>
//<NavLink key={nav.id} id={'submenu_' + nav.name}  to={nav.link} onClick={handleClick} className="subtopic p-2 link-secondary">{nav.name}</NavLink>