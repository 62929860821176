import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

export interface AuthSpaceProps {
    offcanvas   : boolean
    logged_in   : boolean
    logout      : MouseEventHandler
}
function AuthLinks(props:AuthSpaceProps) {
    let attributes = {}
    if (props.offcanvas === true){
        attributes = {
            "data-bs-dismiss" : "offcanvas"
        }
    }
    return (
        <>
            {(props.logged_in)?(
                <>
                    <Link to="/admin" {...attributes} className={(props.offcanvas)?'link-secondary':"text-dark user-link"}>Users</Link>
                    <Link to="/mypage" {...attributes} className={(props.offcanvas)?'link-secondary':"text-dark user-link"}>MyPage</Link>
                    <a className={(props.offcanvas)?'link-secondary':"text-dark user-link"} {...attributes} onClick={props.logout}>Logout</a>
                </>
            ):(
                <>
                    <Link to={"/login"} {...attributes} className="btn btn-outline-secondary">Sign in</Link>
                    <Link to={"/register"} {...attributes} className="btn btn-outline-secondary">Sign up</Link>
                </>
            )}
            
        </>
    )
}
export default AuthLinks;