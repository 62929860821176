import React, { useState, ChangeEvent, FormEvent } from "react";

function Footer() {
   
    return (
        <footer className="footer">
            <p>News <a href="https://userv.info/">Userv</a> by <a href="https://twitter.com/4creatwit">@4creatwit</a>.</p>
            <p>
                <a href="#topOfPage">Back to top</a>
            </p>
        </footer>
    );
}

export default Footer;