import { InputHTMLAttributes, FC } from "react";

import './form-input.css';

type FromInputProps = { label: string, id:string } &
InputHTMLAttributes<HTMLInputElement>;

const FormInput: FC<FromInputProps> = ({ label, id, ...otherProps }) => {
    return (
        <div className="form-floating">
            <input id={id} {...otherProps} />
            {
                label &&
                <label htmlFor={id} >
                    {label}
                </label>
            }
        </div>
    );
}

export default FormInput;