import React from "react";

interface EditorialCardProps {
    title: string
    text:string
    image: string
    link: string
}

function EditorialCard(props:EditorialCardProps) {
    return (
        <div className="col-sm-4 mb-5 mb-sm-2">
            <div className="position-relative image-hover">
                <img src={props.image} className="img-fluid" alt={props.text} width="200" height="200" />
                <a href={props.link}><span className="thumb-title">{props.title}</span></a>
            </div>
            <h5 className="font-weight-600 mt-3">
                {props.text}
            </h5>
      </div>
    )
}
export default EditorialCard;