import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { registerUser, getCurrentUser } from "../../services/auth.service";
import { RegisterResponse } from '../../types/services'
import FormInput from '../../components/form-input/form-input';
import { objectLength } from '../../utils/tools'
import UserType from '../../types/user.type'

const defaultRegFormFields = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    terms:false,
}

function Register() {
    const [user, setUser] = useState<UserType | null>()
    const [formFields, setFormFields] = useState(defaultRegFormFields)
    const [termsCheck, settermsCheck] = useState(false)
    const { firstname, lastname, email, password, terms } = formFields
    const [errorLoginMessage, setErrorLoginMessage] = useState<string | null>(null)
    let navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        if (getCurrentUser() !== null){
            navigate("/mypage");
        }
    }, []);
    const resetFormFields = () => {
        return (
            setFormFields(defaultRegFormFields)
        );
    }
    // handle input changes
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        if (name === 'terms') {
            settermsCheck(!termsCheck);
            formFields.terms = !termsCheck
        }
        else setFormFields({...formFields, [name]: value })
    }
  
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            // make the API call
            const res:RegisterResponse = await registerUser(firstname, lastname, email, password, terms)
            const user:UserType = res.user;
            if ( res.error === 1 ) setErrorLoginMessage(res.message);
            if ( res.error === 0 && user.token) {
                setUser(user);
                localStorage.setItem("user", JSON.stringify(user));
                resetFormFields()
                navigate("/mypage");
            }
        }
        catch (error:any) {
            console.log({error});
            setErrorLoginMessage(objectLength(error) + ' ' + error.message);
        }
    };

    return (
        <>  { (user) ? (<>
                <div className="container">
                    <div className="mt-4 text-center">You are already logged in</div>
                </div>
            </>):(
                <div className="form-signup auth-form">
                    <form onSubmit={handleSubmit}>
                        <h1 className="h3 mb-3 text-center fw-normal">Create New Account</h1>
                        {errorLoginMessage && <>
                            <div className="alert alert-danger" role="alert">
                                {errorLoginMessage}
                            </div>
                        </>}
                        <FormInput
                            id="floatingFirstnameInput"
                            className="form-control"
                            label="Fisrtname"
                            type="text"
                            required
                            name="firstname"
                            value={firstname}
                            onChange={handleChange}
                            placeholder="Fisrtname"
                        />
                        <FormInput
                            id="floatingLastnameInput"
                            className="form-control"
                            label="Lastname"
                            type="text"
                            required
                            name="lastname"
                            value={lastname}
                            onChange={handleChange}
                            placeholder="Lastname"
                        />
                        <FormInput
                            id="floatingEmailInput"
                            className="form-control"
                            label="Email"
                            type="email"
                            required
                            name="email"
                            value={email}
                            onChange={handleChange}
                            placeholder="name@example.com"
                        />
                        <FormInput
                            id="floatingPasswordInput"
                            className="form-control" 
                            label="Password"
                            type='password'
                            required
                            name='password'
                            value={password}
                            onChange={handleChange}
                            placeholder="Password"
                        />
                        <div className="checkbox mb-3">
                            <label>
                                <input type="checkbox" checked={termsCheck} name="terms" onChange={handleChange} required /> Agree to Terms
                            </label>
                        </div>
                        <button className="w-100 btn btn-lg btn-primary" type="submit">Sign up</button>
                    </form>
                    <div className="my-2">
                        Already an account? <Link to="/login">Sign In</Link>
                    </div>
                </div>
            )}
        </>
    );
}

export default Register;
