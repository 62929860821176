import React, { useEffect, useState, MouseEventHandler } from "react";
import { languageType } from "../../types/user.type";

interface LangsDDProps {
    langs: languageType[] | null
    selected: string | null
    action: MouseEventHandler
}

function LangsDD(props:LangsDDProps) {
    const [langList, setLangList] = useState<Array<string>>([])
    // const [langDetails, setLangDetails] = useState<object|null>(null)
    
    useEffect(() => {
        const setLangsDropDown = () => {
            const langsObj = props.langs
            if (langsObj !== null ){
                let list = []
                for (const l of langsObj){
                    list.push(l.iso2.toUpperCase())
                }
                setLangList(list)
            }
        }
        setLangsDropDown()
    }, []);
    
    return (
        <>
            { (props.selected !== null && langList.length > 1)?(
                <div className="dropdown language-dropdown">
                    <button className="btn dropdown-toggle" id="langsDropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        {props.selected}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="langsDropdown">
                        {langList.map((l, key) => {
                            return (
                                <li key={key}>
                                    <a className="dropdown-item" href="#" onClick={props.action} data-lang={l}>{l}</a>
                                </li>
                            )
                        })}
                        
                    </ul>
                </div>
            ):('') }
            
        </>
    )
}
export default LangsDD;



