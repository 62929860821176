import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { getNewsData } from "../../utils/data-utils";
import { getUserPrefs } from '../../services/app.service'
import { QueryType, ArticleType, RowType } from '../types/ArticleTypes'
import TopArticle from './TopArticle';
import MidArticle from './MidArticle';
import BotArticle from './BotArticle';
import "../../styles/news.scss"

const NewsArticles: React.FC = () => {
    const DEFAULT_TOPIC = "global"
    let { topic } = useParams();
    if (topic === undefined) topic = DEFAULT_TOPIC
    const [loading, setLoading] = useState(false)
    const [top, setTop] = useState<RowType>()
    const [mid, setMid] = useState<RowType>()
    const [bot, setBot] = useState<RowType>()
    useEffect(() => {
        const PageData = async () => {
            setLoading(true)
            const lang = getUserPrefs().siteLang.toLowerCase()
            const NewsData:QueryType = await getNewsData(topic, lang)
            if (NewsData.err === 0) {
                setTop(NewsData.articles.top)
                setMid(NewsData.articles.mid)
                setBot(NewsData.articles.bot)
            }
            setLoading(false)
        }
        PageData()
        
    }, []);
    return (
        <>
            {loading && 
                <div className="loading_prepage">
                    <div className="loading_wrapper">
                        <h1 className="loading">loading... </h1>
                    </div>
                </div>
            }
            
            {!loading && top && 
                <>
                    {top.rows.map((article:ArticleType, key:any) => <TopArticle articleValues={article} key={key} />)} 
                </>
            }
            {!loading && mid && 
                <div className="row align-items-md-stretch mb-4">
                    {mid.rows.map((article:ArticleType, key:any) => <MidArticle articleValues={article} key={key} />)} 
                </div>
            }

            {!loading && bot && 
                <div className="align-items-md-stretch">
                    {bot.rows.map((article:ArticleType, key: any) => <BotArticle articleValues={article} key={key} />)}
                </div>
            }
        </>
    )
}
export default NewsArticles;