import React from "react";
import { ArticleType } from '../types/ArticleTypes'
import { fixText, fixDate } from "../../utils/tools";
export interface MidArticleProps {
    articleValues: ArticleType
}
function MidArticle(props:MidArticleProps) {
    const article       = props.articleValues
    const pubDate       = fixDate(article.published_at)
    const title         = fixText(article.title)
    const description   = fixText(article.description)
    return (
        <article className="col-md-6 article-no-img-jumbotron second-mid-article" data-articleid={article.id}>
            <div className="h-100 p-5 bg-light border rounded-3">
                <strong className="d-inline-block mb-2 text-primary">{article.source}</strong>
                <h2 className="mt-2">{title}</h2>
                <div>{pubDate}</div>
                <div className="">Author: {article.author}</div>
                <p className="desc-article">{description}</p>
                <a href={article.url} target="_blank" rel="noreferrer" className="btn btn-outline-secondary" type="button">Read the article</a>
                <span className="art_id">id: {article.id}</span>
            </div>
        </article>
    )
}
export default MidArticle;

